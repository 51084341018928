import React from "react";
import styles from "./contact.module.css";
import phone from "../../images/phone.svg";
import mail from "../../images/mail.svg";
import address from "../../images/map.svg";
import { useTranslation } from "react-i18next";

function ContactPage() {
  const { t } = useTranslation();
  return (
    <div className={styles.map_container}>
      <div className={styles.two_column_text}>
        <div className={styles.text}>
          {" "}
          <strong>D.o.o. Victory style</strong> <br />
          <br />
          {t("VAT")}: 105787289 <br />
          <br />
          {t("Reg. No.")}: 20458810 <br />
          <br />
          <img
            loading="lazy"
            src={address}
            alt="Victory style"
            width={20}
            height={20}
          />{" "}
          <a
            target="_blank"
            className={styles.link}
            rel="noreferrer"
            href="https://www.google.com/maps/dir//VICTORY+STYLE+-+Proizvodnja+muske+i+zenske+modne+konfekcije+-+Usluzno+sivenje,+Ulica+Oslobo%C4%91enja+97+Rumenka,+%D0%9D%D0%BE%D0%B2%D0%B8+%D0%A1%D0%B0%D0%B4+21201,+%D0%A1%D1%80%D0%B1%D0%B8%D1%98%D0%B0/@45.2744313,19.7241592,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x475b13bc8afcf0c5:0xe59d70b5f89ffb57!2m2!1d19.7300574!2d45.2967525?entry=ttu">
            {t("Ulica Oslobođenja 97, 21201 Rumenka")}
          </a>
        </div>
        <br />
        <div className={styles.text}>
          {" "}
          <img
            loading="lazy"
            src={mail}
            alt="Victory style"
            width={20}
            height={20}
          />{" "}
          <a className={styles.link} href="mailto:victory.style@hotmail.com">
            victory.style@hotmail.com
          </a>{" "}
          <br />
          <br />
          <img
            loading="lazy"
            src={phone}
            alt="Victory style"
            width={20}
            height={20}
          />{" "}
          <a className={styles.link} href="tel:+38162499771">
            +381 62 499 771
          </a>{" "}
          <br />
          <br />
          <img
            loading="lazy"
            src={phone}
            alt="Victory style"
            width={20}
            height={20}
          />{" "}
          <a className={styles.link} href="tel:+38162499775">
            +381 62 499 775
          </a>{" "}
          <br />
          <br />
          <img
            loading="lazy"
            src={phone}
            alt="Victory style"
            width={20}
            height={20}
          />{" "}
          <a className={styles.link} href="tel:+381216216683">
            +381 21 621 66 83
          </a>
        </div>
      </div>
      <div
        className={styles.text}
        style={{ paddingBottom: "20px", paddingTop: "20px" }}>
        * {`${t("career")}`}
      </div>
      <iframe
        id="map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2806.5778096588274!2d19.727482476204514!3d45.29675247107174!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475b13bc8afcf0c5%3A0xe59d70b5f89ffb57!2sVICTORY%20STYLE%20-%20Proizvodnja%20muske%20i%20zenske%20modne%20konfekcije%20-%20Usluzno%20sivenje!5e0!3m2!1ssr!2srs!4v1687622224230!5m2!1ssr!2srs"
        width="100%"
        height="450"
        style={{ border: 0 }}
        title="Victory Style"
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"></iframe>
    </div>
  );
}

export default ContactPage;
