import ContactPage from "../pages/Contact/Contact.page";
import HomePage from "../pages/Home/Home.page";
import WhatWeDoPage from "../pages/WhatWeDo/WhatWeDo.page";
//----------------------------------------------------------------------------------------------------------------------------

const routes = [
    {
        path: "*",
        component: HomePage 
    },
    {
        path: "/what-we-do",
        component: WhatWeDoPage,
        title: 'What we do'
    },
    {
        path: "/contact",
        component: ContactPage,
        title: 'Contact'
    },
]

export default routes;