import React from "react";
import styles from "./footer.module.css";
import mail from "../../images/mail.svg";
import phone from "../../images/phone.svg";
import map from "../../images/map.svg";
import logo from "../../images/grey_vs_logo.svg";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();
  return (
    <div className={styles.footer_container}>
      <div className={styles.footer_info}>
        <img
          src={logo}
          alt="Victory style"
          className={styles.footer_logo}
          loading="lazy"
        />
        <div className={styles.footer_content}>
          <div className={styles.column}>
            <div className={styles.footer_contact}>
              <img
                src={phone}
                alt="phone"
                className={styles.footer_icon}
                loading="lazy"
              />
              <a href="tel:+38162499771" className={styles.footer_number}>
                +38162499771
              </a>
            </div>
            <div className={styles.footer_contact}>
              <img
                src={phone}
                alt="phone"
                className={styles.footer_icon}
                loading="lazy"
              />
              <a href="tel:+38162499775" className={styles.footer_number}>
                +38162499775
              </a>
            </div>
          </div>
          <div className={styles.column}>
            <div className={styles.footer_contact}>
              <img
                src={mail}
                alt="mail"
                className={styles.footer_icon}
                loading="lazy"
              />
              <a
                href="mailto:victory.style@hotmail.com"
                className={styles.footer_number}>
                victory.style@hotmail.com
              </a>
            </div>
            <div className={styles.footer_contact}>
              <img
                src={map}
                alt="location"
                className={styles.footer_icon}
                loading="lazy"
              />
              <a
                target="_blank"
                rel="noreferrer"
                className={styles.footer_number}
                href="https://www.google.com/maps/dir//VICTORY+STYLE+-+Proizvodnja+muske+i+zenske+modne+konfekcije+-+Usluzno+sivenje,+Ulica+Oslobo%C4%91enja+97+Rumenka,+%D0%9D%D0%BE%D0%B2%D0%B8+%D0%A1%D0%B0%D0%B4+21201,+%D0%A1%D1%80%D0%B1%D0%B8%D1%98%D0%B0/@45.2744313,19.7241592,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x475b13bc8afcf0c5:0xe59d70b5f89ffb57!2m2!1d19.7300574!2d45.2967525?entry=ttu">
                {t("Ulica Oslobođenja 97, 21201 Rumenka")}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
