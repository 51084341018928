import React from 'react'
import styles from './wrapper.module.css';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

function WrapperComponent({child}) {
  
  return (
    <div className={styles.container}>
        <Header />
        <div className={styles.child}>{child}</div>
        <Footer />
    </div>
  )
}

export default WrapperComponent