import React, { useState, useEffect } from "react";
import styles from "../Home/home.module.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import innovation from "../../images/innovation.svg";
import flexibility from "../../images/quality.svg";
import reliability from "../../images/reliability.svg";
import storage from "../../images/img28.webp";

function HomePage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [age, setAge] = useState();
  const countYears = () => {
    const dob = new Date("02/24/1997");
    let month_diff = Date.now() - dob.getTime();
    let age_dt = new Date(month_diff);
    let year = age_dt.getUTCFullYear();
    setAge(Math.abs(year - 1970));
  };

  useEffect(() => {
    countYears();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.home}>
        <div className={styles.year}>{age}</div>
        <h1 className={styles.subtitle}>{t("years with you!")}</h1>
        <div className={styles.title}>
          {t("Specialized in the production of fashion garments")}
        </div>
        <div className={styles.button} onClick={() => navigate("/what-we-do")}>
          {t("About us")}
        </div>
      </div>
      <div className={styles.second_container}>
        <h1 className={styles.second_subtitle}>
          {t("It`s Built Into Our Fiber")}
        </h1>
        <div className={styles.intro}>
          {t(
            "With our comprehensive service, covering everything from crafting to packaging, you can trust that your garments will be ready for delivery, enhancing your offering of high-quality products and leaving a lasting impression on your customers."
          )}
        </div>
        <div className={styles.quality_row}>
          <div className={styles.colored_container}>
            <div className={styles.quality_column}>
              <img
                loading="lazy"
                src={innovation}
                alt="proizvodnja modne konfekcije"
                className={styles.icon}
              />
              <h2 className={styles.column_title}>{t("FLEXIBILITY")}</h2>
              <div className={styles.column_description}>
                {t(
                  "Work with a partner that can quickly and confidently respond to your project`s needs, budget, and deadline."
                )}
              </div>
            </div>
            <div className={styles.quality_column}>
              <img
                loading="lazy"
                src={flexibility}
                alt="proizvodnja modne konfekcije"
                className={styles.icon}
              />
              <h2 className={styles.column_title}>{t("QUALITY")}</h2>
              <div className={styles.column_description}>
                {t(
                  "Deeply ingrained in the foundations of our production is an unwavering commitment to exceptional quality. For decades now, every piece that leaves our workshops is a piece of high quality."
                )}
              </div>
            </div>
            <div className={styles.quality_column}>
              <img
                loading="lazy"
                src={reliability}
                alt="proizvodnja modne konfekcije"
                className={styles.icon}
              />
              <h2 className={styles.column_title}>{t("RELIABILITY")}</h2>
              <div className={styles.column_description}>
                {t(
                  "Turn to a partner you can count on to come through when it matters, delivering exactly what you need when you need it."
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.full_width_image_container}>
        <img
          loading="lazy"
          src={storage}
          alt="Victory Style coats manufacturer"
          className={styles.full_width_image}
        />
        <div className={styles.full_width_image_text}>
          <div>
            {t(
              "Our dedicated team of experts follows the production process from the very beginning, taking care of every step, from crafting to meticulous packaging. Our skilled artisans carefully create each piece, using the finest materials and applying precision in every detail. After the production is completed, each garment undergoes a meticulous quality control process to ensure it meets the highest standards."
            )}
          </div>

          <div>
            {t(
              "With ample storage capacity, we can provide you with a secure and reliable space. Our state-of-the-art warehouse unit ensures safety and meticulous preservation of each piece, making them ready for dispatch to your stores."
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
