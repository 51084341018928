import i18next from "i18next";

const setLanguage = (language) => {
  const newLanguage = language === "GB" ? "en" : "rs";
  i18next.changeLanguage(newLanguage);
  localStorage.setItem("lng", newLanguage);
};
const getLanguage = () => {
  return localStorage.getItem("lng") ?? 'rs';
};

const languageHelper = {
  setLanguage,
  getLanguage,
};

export default languageHelper;
