import "./App.css";
import { Routes, BrowserRouter, Route } from "react-router-dom";
import routes from "./routes/routes";
import WrapperComponent from "./components/Wrapper/Wrapper.component";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          {routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={<WrapperComponent child={<route.component {...route?.props} />} />}
            />
          ))}
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
