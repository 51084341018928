import React from "react";
import References from "../../components/References/References";
import styles from "./whatWeDo.module.css";
import { useTranslation } from "react-i18next";
import img1 from "../../images/img1.webp";
import img2 from "../../images/img2.webp";
import img3 from "../../images/img3.webp";
import img4 from "../../images/img4.webp";
import img5 from "../../images/img5.webp";
import img6 from "../../images/img6.webp";
import img7 from "../../images/img7.webp";
import img8 from "../../images/img8.webp";
import img9 from "../../images/img9.webp";
import { Gallery } from "react-grid-gallery";
import pants from "../../images/pants.webp";
import blazer from "../../images/blazer.webp";
import coat from "../../images/coat.webp";
import hero from "../../images/3.webp";

function WhatWeDoPage() {
  const { t } = useTranslation();
  const IMAGES = [
    {
      src: img1,
      width: 850,
      height: 450,
      caption: t("Precision cutting"),
    },
    {
      src: img2,
      width: 320,
      height: 212,
      caption: t("New equipment"),
    },
    {
      src: img3,
      width: 220,
      height: 150,
      caption: t("Specialized equipment"),
    },
    {
      src: img4,
      width: 320,
      height: 174,
      caption: t("New equipment"),
    },
    {
      src: img5,
      width: 320,
      height: 212,
      caption: t("Specialized equipment"),
    },
    {
      src: img6,
      width: 320,
      height: 112,
      caption: t("Spacious warehouse"),
    },
    {
      src: img7,
      width: 320,
      height: 274,
      caption: t("Specialized equipment"),
    },
    {
      src: img8,
      width: 320,
      height: 312,
      caption: t("Specialized equipment"),
    },
    {
      src: img9,
      width: 220,
      height: 150,
      caption: t("Large capacity"),
    },
  ];

  const images = IMAGES.map((image) => ({
    ...image,
    customOverlay: (
      <div className={styles.custom_overlay}>
        <div>{image.caption}</div>
      </div>
    ),
  }));
  return (
    <>
      <div className={styles.what_we_do_page}>
        <div className={styles.image_container}>
          <img
            src={hero}
            alt="Victory Style pants pattern"
            className={styles.responsive_image}
          />
        </div>
        <div style={{ height: "20px" }}></div>
        <div className={styles.introduction}>
          <h1 className={styles.headline}>{t("We Are Reliable Partners")}</h1>
          <h2 className={styles.description}>{t("About us - introduction")}</h2>
        </div>
        <References />
        <div className={styles.introduction}>
          <h1 className={styles.headline}>{t("Capacity")}</h1>
          <h2 className={styles.description}>
            {t("Capacity Description")} <h4>{t("vision")}</h4>
          </h2>

          <div className={styles.capacity}>
            <div>
              <img
                src={pants}
                alt="Victory Style pants pattern"
                className={styles.responsive_image}
              />
              <h3 className={styles.capacity_image}>4000/{`${t("month")}`}</h3>
            </div>
            <div>
              <img
                src={blazer}
                alt="Victory Style blazer pattern"
                className={styles.responsive_image}
              />
              <h3 className={styles.capacity_image}>2000/{`${t("month")}`}</h3>
            </div>
            <div>
              <img
                src={coat}
                alt="Victory Style coat pattern"
                className={styles.responsive_image}
              />
              <h3 className={styles.capacity_image}>1500/{`${t("month")}`}</h3>
            </div>
          </div>
        </div>
        <div className={styles.introduction}>
          <h1 className={styles.headline}>{t("Success key")}</h1>
          <h2 className={styles.description}>{t("Success description")}</h2>
        </div>
        <Gallery images={images} enableImageSelection={false} />
      </div>
    </>
  );
}

export default WhatWeDoPage;
