import "./references.css";
import React from "react";
import mcQueen from "../../images/mcQueen.svg";
import almgwand from "../../images/almgwand.svg";
import tiffany from "../../images/tiffany.svg";
import mona from "../../images/mona.svg";
import legend from "../../images/legend.svg";
import airSerbia from "../../images/air_serbia.svg";

const References = () => {
  const images1 = [
    { image: mcQueen, alt: "Alexander McQueen" },
    { image: almgwand, alt: "Almwand" },
    { image: airSerbia, alt: "Air Serbia" },
    { image: mona, alt: "Mona" },
  ];
  const images2 = [
    { image: tiffany, alt: "Tiffany" },
    { image: mona, alt: "Mona" },
    { image: legend, alt: "Legend World Wide" },
    { image: mcQueen, alt: "Alexander McQueen" },
  ];
  const images3 = [
    { image: airSerbia, alt: "Air Serbia" },
    { image: mcQueen, alt: "Alexander McQueen" },
    { image: mona, alt: "Mona" },
    { image: almgwand, alt: "Almwand" },
  ];
  return (
    <>
      <div className="references-container">
        <div className="references-container01">
          <div className="references-container02 marquee">
            <div className="marquee-content marquee-animation1">
              {images1.map((item, index) => {
                return (
                  <img
                    key={index}
                    alt={item.alt}
                    src={item.image}
                    loading="lazy"
                    className={"references-image"}
                  />
                );
              })}
            </div>
            {/* Mirrors the images  */}
            <div className="marquee-content marquee-animation1">
              {images1.map((item, index) => {
                return (
                  <img
                    key={index}
                    alt={item.alt}
                    src={item.image}
                    loading="lazy"
                    className={"references-image"}
                  />
                );
              })}
            </div>
          </div>

          <div className="references-container03 marquee">
            <div className="marquee-content marquee-animation2">
              {images2.map((item, index) => {
                return (
                  <img
                    key={index}
                    alt={item.alt}
                    src={item.image}
                    loading="lazy"
                    className={"references-image"}
                  />
                );
              })}
            </div>
            {/* Mirrors the images  */}
            <div className="marquee-content marquee-animation2">
              {images2.map((item, index) => {
                return (
                  <img
                    key={index}
                    alt={item.alt}
                    src={item.image}
                    loading="lazy"
                    className={"references-image"}
                  />
                );
              })}
            </div>
          </div>

          <div className="references-container02 marquee">
            <div className="marquee-content marquee-animation3">
              {images3.map((item, index) => {
                return (
                  <img
                    key={index}
                    alt={item.alt}
                    src={item.image}
                    loading="lazy"
                    className={"references-image"}
                  />
                );
              })}
            </div>
            {/* Mirrors the images  */}
            <div className="marquee-content marquee-animation3">
              {images3.map((item, index) => {
                return (
                  <img
                    key={index}
                    alt={item.alt}
                    src={item.image}
                    loading="lazy"
                    className={"references-image"}
                  />
                );
              })}
            </div>
          </div>
          <div className="references-container35"></div>
          <div className="references-container36"></div>
        </div>
        <div> </div>
      </div>
    </>
  );
};
export default References;
