import React, { useState, useEffect } from "react";
import styles from "./header.module.css";
import { useNavigate } from "react-router-dom";
import ReactCountryFlag from "react-country-flag";
import languageHelper from "../../helpers/languageHelper";
import { useTranslation } from "react-i18next";

function Header() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isOpen, setIsOpen] = useState(false);
  const [language, setLanguage] = useState(
    languageHelper.getLanguage() !== "rs" ? "RS" : "GB"
  );
  const [isScroll, setIsScroll] = useState(false);
  const isHome = window.location.pathname === "/";

  window.addEventListener("click", function (e) {
    if (!document.getElementById("top-nav")?.contains(e.target)) {
      if (isOpen) {
        setIsOpen(false);
      }
    }
  });

  document.addEventListener("scroll", () => {
    const lastKnownScrollPosition = window.scrollY;
    if (lastKnownScrollPosition > 0) {
      setIsScroll(true);
    } else {
      setIsScroll(false);
    }
  });

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeLanguage = () => {
    if (language === "GB") {
      languageHelper.setLanguage("GB");
      setLanguage("RS");
    } else {
      languageHelper.setLanguage("RS");
      setLanguage("GB");
    }
  };

  return screenWidth > 767 ? (
    <div
      className={styles.header_container}
      style={{
        backgroundColor:
          isScroll || !isHome
            ? "var(--dl-background-color)"
            : "rgb(217 217 217 / 60%)",
      }}>
      <div className={styles.logo_name}>
        <img
          src="logo_vs_small.svg"
          alt="Victory style"
          loading="lazy"
          width={80}
          height={80}
          className={styles.logo}
          onClick={() => {
            navigate("/");
            setIsOpen(false);
          }}
        />
        <div className={styles.name}>Victory style</div>
      </div>
      <div className={styles.navigation_container}>
        <nav className={styles.navigation_menu}>
          <ul>
            <li
              onClick={() => {
                navigate("/");
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}>
              {t("Home")}
            </li>
            <li
              onClick={() => {
                navigate("/what-we-do");
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}>
              {t("About")}
            </li>
            <li
              onClick={() => {
                navigate("/contact");
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}>
              {t("Contact")}
            </li>
            <li onClick={() => changeLanguage()} className={styles.flag}>
              <ReactCountryFlag
                countryCode={language}
                svg
                style={{
                  width: "18px",
                  height: "18px",
                }}
                title={language}
              />
            </li>
          </ul>
        </nav>
      </div>
    </div>
  ) : (
    <>
      <div
        id="top-nav"
        className={styles.top_nav}
        style={{
          backgroundColor:
            isScroll || isOpen || !isHome ? "var(--dl-background-color)" : "",
          boxShadow: isOpen ? "none" : "",
        }}>
        <div className={styles.logo_name}>
          <img
            src="logo_vs_small.svg"
            alt="Victory style"
            loading="lazy"
            className={styles.logo}
            width={55}
            height={55}
            onClick={() => navigate("/")}
          />
          <div className={styles.name}>Victory style</div>
        </div>
        <div onClick={() => changeLanguage()} className={styles.flag}>
          <ReactCountryFlag
            countryCode={language}
            svg
            style={{
              width: "24px",
              height: "24px",
            }}
            title={language}
          />
        </div>
        <label className={styles.menu_button_container}>
          <div
            className={styles.menu_button}
            onClick={() => setIsOpen(!isOpen)}></div>
        </label>
      </div>

      {isOpen && (
        <ul className={styles.mobile_list}>
          <li
            onClick={() => {
              navigate("/");
              setIsOpen(!isOpen);
            }}>
            {t("Home")}
          </li>
          <li
            onClick={() => {
              navigate("/what-we-do");
              setIsOpen(!isOpen);
            }}>
            {t("About")}
          </li>
          <li
            onClick={() => {
              navigate("/contact");
              setIsOpen(!isOpen);
            }}>
            {t("Contact")}
          </li>
        </ul>
      )}
    </>
  );
}

export default Header;
